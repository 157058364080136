//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import am4themes_dark from "@amcharts/amcharts4/themes/dark";

import { mapGetters, mapActions } from 'vuex';
import eventBus from '@/plugins/eventBus';
import * as _ from 'underscore';

am4core.useTheme(am4themes_animated);
export default {
  name: 'flows',
  data() {
    return {
      showChart: true,
      showInnerLoading: false,
      Traceable_Sankey_data: [],
      isCall: false,
      showIntents: true,
      // pagination
      showPagination: false,
      currentPage: 1,
      pageSize: 5,
      totalPages: 1,
      tracy_pagination_data: [],
      showTracySingle: true,
      showlessThanten: false,
      topicData: [],
      heigest_level: 0,
      common_point: {},
      common_dropout: {},
      avgInteractions: 0,
    };
  },
  computed: {
    ...mapGetters({
      getFilterDate: 'getFilterDate',
      currentAIAccount: 'getCurrentAIAccount',
      currentUser: 'getCurrentUser',
      darkTheme: 'getDarkTheme',
    }),
  },
  methods: {
    ...mapActions({
      requestConversationsFlowPlain: 'requestConversationsFlowPlain',
      requestConversationsTopicParameters: 'requestConversationsTopicParameters',
      requestAISessionsList: 'requestAISessionsList',
      generateXMLFile: 'generateXMLFile',
    }),
    generateTraceableSankeyChart() {
      const that = this;
      if (that.chart) {
        that.chart.dispose();
      }
      if(this.darkTheme === true){
        am4core.useTheme(am4themes_dark);
      }else{
        am4core.unuseTheme(am4themes_dark)
      }
      const chart = am4core.create( that.$refs.traceableSankey, am4charts.SankeyDiagram);
      chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

      chart.data = that.tracy_pagination_data;
      const hoverState = chart.links.template.states.create('hover');
      hoverState.properties.fillOpacity = 0.6;
      chart.dataFields.fromName = 'from';
      chart.dataFields.toName = 'to';
      chart.dataFields.value = 'value';
      chart.links.template.propertyFields.id = 'id';
      chart.links.template.colorMode = 'solid';
      chart.links.template.fill = new am4core.InterfaceColorSet().getFor('alternativeBackground');
      chart.links.template.fillOpacity = 0.1;
      chart.links.template.tooltipText = '';

      // highlight all links with the same id beginning
      chart.links.template.events.on('over', (event) => {
        const link = event.target;
        const nodeTemplate = chart.nodes.template;
        nodeTemplate.showSystemTooltip = false;
        const id = link.id.split('-')[0];
        chart.links.each((link) => {
          if (link.id.startsWith(`${id}-`)) {
            link.isHover = true;
          }
        });
      });

      const nodeTemplate = chart.nodes.template;
      nodeTemplate.showSystemTooltip = false;
      nodeTemplate.inert = true;
      nodeTemplate.readerTitle = 'Drag me!';
      nodeTemplate.width = 20;
      nodeTemplate.showTooltipOn = 'hover';
      nodeTemplate.events.on('over', (event) => {
        const item = {
          name: event.target.name,
          incoming: event.target.incomingDataItems.length,
          outgoing: event.target.outgoingDataItems.length,
          dropOutRate: 0,
        };
        const { percentage } = event.target.dataItem.dataContext;
        let totalPercentage; let
          dropOutRate;
        if (item.incoming > 0) {
          totalPercentage = Number((percentage * item.incoming).toFixed(2));
        } else {
          totalPercentage = Number((percentage * item.outgoing).toFixed(2));
        }
        if (item.incoming > 0 && item.outgoing > 0) {
          dropOutRate = 100 - Number(((item.outgoing / item.incoming) * 100).toFixed(2));
        } else if (item.incoming == 0) {
          dropOutRate = 0;
        } else if (item.outgoing == 0) {
          dropOutRate = 100;
        }
        event.target.tooltipText = `${item.name}
        ${totalPercentage}%
        ${dropOutRate}% dropout rate`;

        // incoming: ${item.incoming}
        // outgoing: ${item.outgoing}
      });
      //  =

      //
      if (that.showIntents == false) {
        nodeTemplate.nameLabel.label.text = '';
      }

      chart.links.template.events.on('out', (event) => {
        chart.links.each((link) => {
          link.isHover = false;
        });
      });
      // for right-most label to fit
      chart.paddingRight = 30;

      // Add scrollbar
      const scrollbar = new am4charts.XYChartScrollbar();

      chart.scrollbarX = scrollbar;
      // Enable export
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.filePrefix = `EVO Dynamics report Conversation Flows ${that.currentAIAccount.name} from ${that.getFilterDate.start}  to ${that.getFilterDate.end}`;
      that.chart = chart;
    },
    exportSectionToXLS(reportType) {
      const that = this;
      that.showInnerLoading = true;
      const data = {
        type: reportType,
        aiAccount: that.currentAIAccount.id,
        startdate: that.getFilterDate.start,
        enddate: that.getFilterDate.end,
      };
      that.generateXMLFile(data);
      setTimeout(() => {
        that.showInnerLoading = false;
      }, 1000);
    },
    onDateUpdate(date) {
      const that = this;
      if (that.isValid(date.start) && that.isValid(date.end)) {
        const data = {
          aiAccount: that.currentAIAccount.id,
          startdate: date.start,
          enddate: date.end,
          from: 'onDateUpdate',
        };
        that.isCall = false;
        that.updatesFlowData(data);
      }
    },
    updatesFlowData(data) {
      const that = this;
      if (!that.isCall) {
        that.showChart = true;
        that.showInnerLoading = true;
        that.showInnerLoadingRadar = true;
        that.showInnerLoadingChord = true;
        that.showPagination = false;
        that.isCall = true;
        if (that.chart) {
          that.chart.dispose();
        }
        Promise.all([
          that.requestConversationsFlowPlain(data),
          that.requestConversationsTopicParameters(data),
          that.requestAISessionsList(data),
        ])
          .then((res) => {
            if (res[0].result.length > 0) {
              that.Traceable_Sankey_data = res[0].result;
              that.avgInteractions = Number((that.Traceable_Sankey_data.length / res[2].orderedArr.length).toFixed(2));
              setTimeout(() => {
                that.showInnerLoading = false;
                that.generatePaginationForTraceableSankey();
                that.generateTopicsInfo(res[1].topicData);
              }, 100);
            } else {
              that.showChart = false;
            }
          })
          .catch((err) => {
            that.showChart = false;
            that.showInnerLoading = false;
          })
          .finally(() => {
            that.isCall = false;
          });
      }
    },
    generatePaginationForTraceableSankey() {
      const that = this;
      that.showInnerLoading = true;
      if (that.chart) {
        that.chart.dispose();
      }
      const minValue = that.showTracySingle ? 0 : 1;
      const filteredData = _.filter(that.Traceable_Sankey_data, (obj) => obj.value > minValue);
      if (filteredData.length > 0) {
        const heigest_level = _.max(filteredData, (obj) => obj.level).level || 5;
        that.heigest_level = heigest_level;
        that.totalPages = Math.ceil(heigest_level / that.pageSize);
        const pageStart = (that.currentPage - 1) * that.pageSize;
        const pageEnd = that.pageSize * that.currentPage;
        const groupByLevel = _.groupBy(filteredData, 'level');
        const groupByTo = _.groupBy(filteredData, 'to');
        const groupByFrom = _.groupBy(filteredData, 'from');
        const common_starting_point = [];
        for (var key in groupByFrom) {
          common_starting_point.push({
            intent: key.substr(0, key.indexOf('(')),
            value: _.reduce(
              _.pluck(groupByFrom[key], 'value'),
              (memo, num) => memo + num,
              0,
            ),
          });
        }

        const percentage_parsed_data = [];
        that.common_point = _.sortBy(common_starting_point, 'value').reverse()[0];
        for (var key in groupByLevel) {
          const currentLevel = groupByLevel[key][0].level;
          const levelTotal = _.reduce(
            _.pluck(groupByLevel[key], 'value'),
            (memo, num) => memo + num,
            0,
          );
          for (const subKey in groupByLevel[key]) {
            const obj = groupByLevel[key][subKey];
            const percentage = Number(((obj.value / levelTotal) * 100).toFixed(2));
            const incoming = _.reduce(_.pluck(groupByTo[groupByLevel[key][subKey].to], 'value'), (memo, num) => memo + num, 0);
            const outgoing = _.reduce(_.pluck(groupByFrom[groupByLevel[key][subKey].from], 'value'), (memo, num) => memo + num, 0);
            const dropOutRate = Number(100 - (((outgoing / incoming) * 100)).toFixed(2));
            if (outgoing > incoming) {
              obj.dropOutRate = 0;
            } else {
              obj.dropOutRate = dropOutRate;
            }
            obj.percentage = percentage;
            if (that.showlessThanten == true) {
              if (percentage >= 10) {
                percentage_parsed_data.push(obj);
              }
            } else {
              percentage_parsed_data.push(obj);
            }
          }
        }
        that.common_dropout = _.sortBy(percentage_parsed_data, 'dropOutRate').reverse()[0];
        const pagination_parsed_data = _.filter(percentage_parsed_data, (track) => track.level >= pageStart && track.level <= pageEnd);
        that.tracy_pagination_data = pagination_parsed_data;
        that.showInnerLoading = false;
        that.showPagination = true;
        setTimeout(() => {
          that.generateTraceableSankeyChart();
        }, 100);
      } else {
        that.showInnerLoading = false;
        that.showPagination = true;
      }
    },
    generateTopicsInfo(topicData) {
      const that = this;
      const topicsTotal = _.reduce(_.pluck(topicData, 'value'), (memo, num) => memo + num, 0);
      const sortedValues = _.sortBy(topicData, 'value').reverse();
      _.map(sortedValues, (topic) => {
        topic.percentage = ((topic.value / topicsTotal) * 100).toFixed(2);
        return topic;
      });
      that.topicData = sortedValues;
    },
    onAccountUpdate(account) {
      const that = this;
      const data = {
        aiAccount: account.id,
        startdate: that.getFilterDate.start,
        enddate: that.getFilterDate.end,
        from: 'onAccountUpdate',
      };
      that.isCall = false;
      that.updatesFlowData(data);
    },
    isValid(value) {
      if (
        value === undefined
        || value === null
        || (typeof value === 'object' && Object.keys(value).length === 0)
        || (typeof value === 'string' && value.trim().length === 0)
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
    currentPage(newVal) {
      this.generatePaginationForTraceableSankey();
    },
    pageSize(newVal) {
      this.currentPage = 1;
      this.generatePaginationForTraceableSankey();
    },
    showTracySingle(newVal) {
      this.generatePaginationForTraceableSankey();
    },
    showIntents(newVal) {
      this.generatePaginationForTraceableSankey();
    },
    showlessThanten(newVal) {
      this.generatePaginationForTraceableSankey();
    },
    darkTheme(){
      this.generateTraceableSankeyChart()
    }
  },
  mounted() {
    const that = this;
    const data = {
      aiAccount: that.currentAIAccount.id,
      startdate: that.getFilterDate.start,
      enddate: that.getFilterDate.end,
      from: 'mounted',
    };
    that.updatesFlowData(data);
  },
  created() {
    eventBus.$on('updateDate', this.onDateUpdate);
    eventBus.$on('updateAIAccount', this.onAccountUpdate);
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
    eventBus.$off('updateDate', this.onDateUpdate);
    eventBus.$off('updateAIAccount', this.onAccountUpdate);
  },
  destroyed() {
    eventBus.$off('updateDate', this.onDateUpdate);
    eventBus.$off('updateAIAccount', this.onAccountUpdate);
  },
};
